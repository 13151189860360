import { useContext } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { AmountType } from './interfaces/item';
import CartServicePrices from './interfaces/service-prices';
import { addItem, addRepair, addVoucher, setItems, setNumBoxes, setServicePrices, setVoucherItems } from './store/actions';
import ApiContext from '../api/context';
import { handleError } from '../utils/handleError';
import cookies from 'js-cookie';
import { Product, Repair } from '../types';
import { RepairStatus } from '../enums';
import { v4 as uuidv4 } from 'uuid';
import i18n from 'i18n';

import { VoucherItem } from 'Voucher/intefaces/item';
import AppState from 'interfaces/app-state';

export function groupByGarment(cartItems: Repair[]): Repair[][] {
  return Object.values(_.groupBy(cartItems, 'product.productType._id'));
}

export function groupByGarmentByVoucher(cartItems: VoucherItem[] | undefined): VoucherItem[][] {
  return Object.values(_.groupBy(cartItems, '_id'));
}

export function findByGarmentId(cartItems: Repair[], garmentId: string): Repair | undefined {
  return cartItems.find((x) => x.product.seo?.[i18n.language] === garmentId);
}

export function getRepairPrice(cartItem: Product): number {
  const { priceCent, inputValue, input } = cartItem;

  if (inputValue && input?.type === AmountType.QUANTITY) {
    return priceCent * inputValue;
  }

  return priceCent;
}

export function getVoucherPrice(cartItem: VoucherItem): number {
  const { priceCent } = cartItem;

  return priceCent;
}

export function getGarmentPrice(cartItems: Repair[]): number {
  return cartItems.reduce((acc, x) => acc +
    x.repairs!.reduce((acc, y) => acc + getRepairPrice(y), 0),
    0);
}

export function getRepairsPrice(items: Product[]) {
  return items.reduce((acc, x) => acc + getRepairPrice(x),
    0);
}

export function getVouchersPrice(voucherItems: VoucherItem[]) {
  return voucherItems.reduce((acc, x) => acc + getVoucherPrice(x) / 100,
  0);
}

export function getFullRepairsPrice(items: Repair[]) {
  const products = items.map(x => x.product);
  return getRepairsPrice(products);
}
// private
function getPackagingPrice(numBoxes: number, boxPrice: number) {
  return numBoxes * boxPrice;
}

interface GetFinalPriceProps {
  items: Repair[];
  numBoxes: number;
  servicePrices: CartServicePrices;
}

export function getCourierCost({ items, numBoxes, servicePrices }: GetFinalPriceProps): number {
  const { boxPriceCent, courierPriceCent, shippingExemptionThreshold } = servicePrices;
  const repairsPrice = getFullRepairsPrice(items);
  const packagingPrice = getPackagingPrice(numBoxes, boxPriceCent);
  return repairsPrice + packagingPrice < shippingExemptionThreshold ? courierPriceCent : 0;
}

export function getFinalPrice({ items, numBoxes, servicePrices }: GetFinalPriceProps): number {
  const { boxPriceCent } = servicePrices;
  const repairsPrice = getFullRepairsPrice(items);
  const packagingPrice = getPackagingPrice(numBoxes, boxPriceCent);
  const couriesPrice = getCourierCost({ items, numBoxes, servicePrices });
  return repairsPrice + packagingPrice + couriesPrice;
}

/**
 * Cart module methods.
 *
 * The methods don't catch any errors. All errors are re-thrown to calling
 * components and are meant to be caught and handled by the components themselves.
 */
export function useCartApi() {
  const api = useContext(ApiContext);
  const dispatch = useDispatch();
  const { voucher } = useSelector(
    (state: AppState) => ({
      voucher: state.cart.voucher,
    }),
    shallowEqual
  );
  return {
    reloadCartFromCookie: async () => {
      const { servicePrices, numBoxes } = await api.cart.fetchCartConstants();
      const cartCookie = localStorage.getItem('cart') as string;
      const cartCookieFirst = cookies.get('cart') as string;

      try {
        let cartData;

        if (cartCookie) {
          cartData = JSON.parse(cartCookie);
        } else if (cartCookieFirst && cartCookieFirst.length) {
          cartData = JSON.parse(cartCookieFirst);
        }

        if (cartData) {
          // Use localstorage to save the cart data for future reloading
          localStorage.setItem('cart', JSON.stringify(cartData));

          // Modify the cartData items
          // const cartDataPopulated = await Promise.all(
          //   cartData.map(async (data) => {
          //     const product = await api.product.fetchProductById(data.product._id);
          //     product.itemId = data.product.itemId
          //
          //     return { ...data, product };
          //   })
          // );
          if (!voucher) {
            dispatch(setItems(cartData));
          }

          if (voucher) {
            dispatch(setVoucherItems(cartData));
          }
        }
      } catch (err) {
        cookies.remove('cart');
        handleError('CART_RELOAD_ERROR', "Can't reload cart, broken data");
      }

      if (numBoxes) {
        dispatch(setNumBoxes(numBoxes));
      }

      if (servicePrices) {
        dispatch(setServicePrices(servicePrices));
      }
    },
    addItem: async (productId: string, isAuthorized: boolean, amount?: number, comment?: string, creationTime?: number, imagesQuantity?: number) => {
      const uuid = uuidv4();
      try {
        let cartCookieFirst = cookies.get('cart') as string;
        let cartCookie = localStorage.getItem('cart') as string;

        if (!cartCookie || !cartCookie.length || !cartCookieFirst || !cartCookieFirst.length) {
          localStorage.setItem('cart', '[]');
          cookies.set('cart', '[]');
          cartCookie = cookies.get('cart') as string;
        }

        let cartData = JSON.parse(cartCookie);

    
        const product = await api.product.fetchProductById(productId);
        const newItem = {
          product: { ...product, itemId: uuid, inputComment: comment, inputValue: amount ?? 1 },
          inputValue: amount ?? 1,
          comment,
          imagesQuantity,
          status: RepairStatus.PENDING,
          tailorType: product.tailorType,
          repairs: [{ ...product, uId: uuidv4(), inputValue: amount ?? 1, inputComment: comment, creationTime, imagesQuantity }]
        };
    
        cartData.push(newItem);
        localStorage.setItem('cart', JSON.stringify(cartData));
        cookies.set('cart', JSON.stringify(cartData));
        
        dispatch(addItem(newItem));
        return product;
      } catch (err) {
        handleError('CART_API', err.message);
        return await api.product.fetchProductById(productId);
      }
    },
    addItemToGarment: async (garmentId: string, productId: string, isAuthorized: boolean, itemId?: string, amount?: number, comment?: string, creationTime?: number, imagesQuantity?: number) => {
      try {
        let cartCookie = localStorage.getItem('cart') as string;

        if (!cartCookie || !cartCookie.length) {
          localStorage.setItem('cart', '[]');
          cartCookie = cookies.get('cart') as string;
        }

        let cartData = JSON.parse(cartCookie);
    
        const product = await api.product.fetchProductById(productId);
        const newItem = {
          product: { ...product, inputComment: comment, inputValue: amount ?? 1, creationTime, imagesQuantity },
          inputValue: amount ?? 1,
          comment,
          imagesQuantity,
          status: RepairStatus.PENDING,
          tailorType: product.tailorType
        };
        cartData.forEach(cartProduct => {
          if (cartProduct.product.itemId === itemId) {
            cartProduct.repairs.push({ ...product, uId: uuidv4(), inputComment: comment, creationTime, imagesQuantity, inputValue: amount ?? 1, });
          }
        });
    
        localStorage.setItem('cart', JSON.stringify(cartData));
        cookies.set('cart', JSON.stringify(cartData));
    
        if (itemId) {
          dispatch(addRepair(newItem, itemId));
        }
    
        return product;
      } catch (err) {
        handleError('CART_API', err.message);
      }
    },
    addVoucherToCart: async (voucherId: string) => {
      try {
        let cartCookie = localStorage.getItem('cart') as string;
    
        let cartData = cartCookie ? JSON.parse(cartCookie) : [];
    
        const product = await api.voucher.fetchProductById(voucherId);
        const newItem = { priceCent: product.priceCent, title: product.title };
    
        cartData.push(newItem);
        localStorage.setItem('cart', JSON.stringify(cartData));
        //@ts-ignore
        dispatch(addVoucher([newItem]));
        
        return product;
      } catch (err) {
        handleError('CART_API', err.message);
        return await api.voucher.fetchProductById(voucherId);
      }
    },
  }
}

export function clearDiscountFromStorage() {
  localStorage.setItem('discountCode', '');
  localStorage.setItem('discount', '');
}
