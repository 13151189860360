import React from 'react';
import { groupByCategory } from '../../utils';
import { sendEvent } from '../../../utils/google-analytics';
import ProductCategoryStepButton, { ProductCategoryStepLink } from './Button';
import styles from './List.module.css';
import { Product, ProductCategory } from '../../../types';

// props
interface ProductCategoryStepListProps {
  products: Product[];
  onCategorySelect(category: ProductCategory): void;
  labelledBy?: string;
}

/**
 * Product category step button list component.
 */
export default function ProductCategoryStepList({ products, onCategorySelect, labelledBy }: ProductCategoryStepListProps) {
  const categories = groupByCategory(products);

  const onCategoryClick = (x: ProductCategory) => {
    sendEvent('repair', `category_selected-name_${x.title.de}`, x.title.de);
    onCategorySelect(x);
  };

  function renderButtons() {
    const buttons = categories.map((x, i) => (
      <li className={styles.listItem} key={i}>
        <ProductCategoryStepButton onClick={() => onCategoryClick(x)} name={x.title} {...x} />
      </li>
    ));
    buttons.push(
      <li key={categories.length}>
        <ProductCategoryStepLink />
      </li>
    );
    return buttons;
  }

  return (
    <ul aria-labelledby={labelledBy} className={styles.list}>
      {renderButtons()}
    </ul>
  );
}
