import { RouteComponentProps, useNavigate } from '@reach/router';
import React from 'react';
import { useCartApi } from '../../../Cart/utils';
import AppNotFound from '../../../components/NotFound/NotFound';
import { getProduct } from '../../utils';
import ProductDetailsStepForm from './Form';
import { shallowEqual, useSelector } from 'react-redux';
import AppState from '../../../interfaces/app-state';
import { Product } from '../../../types';
import i18n from 'i18n';

interface ProductDetailsStepGarmentRouteProps {
  seo: string;
  categoryId: string;
  typeId: string;
  repairId: string;
  itemId: string;
}
interface ProductDetailsStepGarmentProps extends RouteComponentProps<ProductDetailsStepGarmentRouteProps> {
  products: Product[];
}

/**
 * "Further Details" component for an existing garment.
 */
export default function ProductDetailsStepGarment({ products, seo, categoryId, typeId, repairId, itemId }: ProductDetailsStepGarmentProps) {
  const { user } = useSelector(
    (state: AppState) => ({
      user: state.auth,
    }),
    shallowEqual
  );
  const cartApi = useCartApi();
  const navigate = useNavigate();
  const product = getProduct(products, categoryId ?? '', typeId ?? '', seo ?? '');

  if (!product || !seo) {
    return <AppNotFound />;
  }

  const handleSubmit = async (amount?: number, comment?: string, creationTime?: number, imagesQuantity?: number) => {
    const { productType }: any = await cartApi.addItemToGarment(seo, product._id, !!user._id, itemId, amount, comment, creationTime, imagesQuantity);
    navigate(`../../../../../success/${productType.seo?.[i18n.language]}`);
  };

  return <ProductDetailsStepForm product={product} onSubmit={handleSubmit} backLinkHref={'../../../'} />;
}
